import Image from "next/image";
import { useState } from "react";
import authbg from "../../assets/bg-logo.svg";
import logo from "../../assets/swiper/logo.png";
import useWindowSize from "src/helper/useWindowSize";
import useLanguage from "src/context/useLanguage";

const RightSidePage = () => {
  const { width } = useWindowSize();
  const [currentStep, setCurrentStep] = useState(0);
  const { language, translations } = useLanguage();
  const rightSide = translations[language].rightPage;
  // Array of components or content for each step
  const steps = [
    <div key="step1">
      <h1
        className={`${
          width <= 768
            ? "text-3xl"
            : width <= 1024
            ? "text-4xl"
            : width <= 1200
            ? "text-5xl"
            : "text-7xl"
        } font-semibold font-ibmArabic text-white mb-4`}
      >
        {rightSide.buy}
      </h1>
      <h1
        className={`${
          width <= 768
            ? "text-3xl"
            : width <= 1024
            ? "text-4xl"
            : width <= 1200
            ? "text-5xl"
            : "text-7xl"
        } font-semibold font-ibmArabic text-white mb-4`}
      >
        {rightSide.gold}
      </h1>
      <p
        className={`${
          width <= 768 ? "text-xl" : width <= 1024 ? "text-2xl" : "text-4xl"
        } font-medium font-ibmArabic text-white mb-4`}
      >
        {rightSide.anytime}
      </p>
      <p
        className={`${
          width <= 768 ? "text-sm" : width <= 1024 ? "text-lg" : "text-xl"
        } font-normal font-ibmArabic text-white`}
      >
        {rightSide.buySell}
        <br /> {rightSide.sabika} <br /> {rightSide.wealth}
      </p>
    </div>,
    <div key="step2">
      <h1
        className={`${
          width <= 768
            ? "text-3xl"
            : width <= 1024
            ? "text-4xl"
            : width <= 1200
            ? "text-5xl"
            : "text-7xl"
        } font-semibold font-ibmArabic text-white mb-4`}
      >
        {rightSide.more}
      </h1>
      <p
        className={`${
          width <= 768 ? "text-xl" : width <= 1024 ? "text-2xl" : "text-4xl"
        } font-medium font-ibmArabic text-white mb-4`}
      >
        {rightSide.less}
      </p>
      <p
        className={`${
          width <= 768 ? "text-sm" : width <= 1024 ? "text-lg" : "text-xl"
        } font-normal font-ibmArabic text-white`}
      >
        {rightSide.enjoy}
        <br /> {rightSide.your}
      </p>
    </div>,
    <div key="step3">
      <h1
        className={`${
          width <= 768
            ? "text-3xl"
            : width <= 1024
            ? "text-4xl"
            : width <= 1200
            ? "text-5xl"
            : "text-7xl"
        } font-semibold font-ibmArabic text-white mb-4`}
      >
        {rightSide.flexible}
      </h1>
      <p
        className={`${
          width <= 768 ? "text-xl" : width <= 1024 ? "text-2xl" : "text-4xl"
        } font-medium font-ibmArabic text-white mb-4`}
      >
        {rightSide.payment}
      </p>
      <p
        className={`${
          width <= 768 ? "text-sm" : width <= 1024 ? "text-lg" : "text-xl"
        } font-normal font-ibmArabic text-white`}
      >
        {rightSide.manage}
        <br /> {rightSide.methods}
      </p>
    </div>,
    <div key="step4">
      <h1
        className={`${
          width <= 768
            ? "text-3xl"
            : width <= 1024
            ? "text-4xl"
            : width <= 1200
            ? "text-5xl"
            : "text-7xl"
        } font-semibold font-ibmArabic text-white mb-4`}
      >
        {rightSide.safety}
      </h1>
      <p
        className={`${
          width <= 768 ? "text-xl" : width <= 1024 ? "text-2xl" : "text-4xl"
        } font-medium font-ibmArabic text-white mb-4`}
      >
        {rightSide.priority}{" "}
      </p>
      <p
        className={`${
          width <= 768 ? "text-sm" : width <= 1024 ? "text-lg" : "text-xl"
        } font-normal font-ibmArabic text-white`}
      >
        {rightSide.trusted}
        <br /> {rightSide.goldTrans}
      </p>
    </div>,
  ];
  // Handler for the "Next" button
  const handleNext = () => {
    setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
  };

  // Handler for the "Back" button
  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep - 1 + steps.length) % steps.length);
  };
  return (
    <div
      className={`
    hidden md:block md:w-1/2 h-full flex-shrink-0 flex-1 bg-[#E9C237] fixed 
    ${language === "en" ? "right-0" : "left-0"}
    `}
    >
      <div className="w-1/2 h-screen bg-[#E9C237] fixed top-0 ">
        <Image
          src={authbg}
          alt="authbg"
          className="w-full h-full object-cover opacity-30"
          priority
        />
      </div>
      <div className="">
        <div className=" absolute right-16 mt-16">
          <Image
            src={logo}
            alt="SabikaLogo"
            className="w-full h-full object-contain"
            priority
          />
        </div>
        <div className="absolute flex flex-col w-full h-full justify-center ">
          <div className="absolute flex flex-col h-full  justify-center   px-16">
            <div className="">{steps[currentStep]}</div>
            {/* Dots Navigation */}
            <div className="flex mt-8 space-x-2">
              {steps.map((_, index) => (
                <div
                  key={index}
                  onClick={() => setCurrentStep(index)}
                  className={`cursor-pointer transition-all duration-300 rounded-full ${
                    currentStep === index
                      ? "bg-white w-16 h-2"
                      : "bg-white bg-opacity-30 w-2 h-2"
                  }`}
                ></div>
              ))}
            </div>
            {/* Navigation Buttons */}
            <div className="flex justify-between mt-16 w-full ">
              <button
                onClick={handleBack}
                className="px-8 py-2  text-base font-normal font-ibmArabic rounded-lg text-white bg-[#FFF] bg-opacity-10"
              >
                {rightSide.back}
              </button>
              <button
                onClick={handleNext}
                className="px-8 py-2 text-base font-normal font-ibmArabic rounded-lg text-white bg-[#FFF] bg-opacity-10"
              >
                {rightSide.next}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSidePage;
